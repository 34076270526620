import React from "react";
import { useIntl } from "gatsby-plugin-intl";

import Hero from "../sections/common/Hero";
import Feature1 from "../sections/flop-events/Feature1";
import Feature2 from "../sections/flop-events/Feature2";
import PageWrapper from "../components/PageWrapper";
import Seo from "../components/Seo/seo";

const EventsPage = () => {
  const intl = useIntl();
  return (
    <>
      <PageWrapper footerDark>
        <Seo
          title="Flop Events"
          desc="Events created in partnership with First Land Of Poker."
        />
        <Hero
          title={intl.formatMessage({
            id: "events.hero.title",
          })}
        >
          {intl.formatMessage({
            id: "events.hero.subtitle",
          })}
        </Hero>
        <Feature1 />
        <Feature2 />
      </PageWrapper>
    </>
  );
};
export default EventsPage;
