import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import { useIntl } from "gatsby-plugin-intl";

import { Title, Section, Box, Text } from "../../components/Core";
import { breakpoints } from "../../utils";

import bannerCGTPng from "../../assets/image/flop/banner_CGT.png";
import bannerPAPCPng from "../../assets/image/flop/banner_PAPC.png";

const SliderStyled = styled(Slider)`
  .slick-dots {
    position: relative;
    margin-top: 10px;
    li {
      font-size: 0;
      width: 17px;
      height: 8px;
      border-radius: 4px;
      background-color: ${({ theme }) => theme.colors.shadow};
      margin-left: 5px;
      margin-right: 5px;
      transition: 0.5s;
      &.slick-active {
        width: 45px;
        height: 8px;
        border-radius: 4px;
        background-color: ${({ theme }) => theme.colors.secondary};
      }
      button {
        width: 100%;
        height: 100%;
        &:before {
          content: none;
        }
      }
    }
  }
`;

const ContentCard = ({
  className,
  image,
  name,
  company,
  children,
  ...rest
}) => (
  <Box
    bg="light"
    border="1px solid"
    borderColor="#f4f4f4"
    p="30px"
    mx="20px"
    borderRadius={10}
    className={`${className}`}
    {...rest}
    css={`
      cursor: pointer;
      &:focus {
        outline: none;
      }
      &:hover {
        background-color: #f4f4f4;
      }
    `}
  >
    <Box className="d-flex justify-content-between">
      <Box className="d-flex justify-content-center align-items-start" mb={2}>
        <img
          src={image}
          alt="Flop Events, patrik antonius poker challenge"
          className="img-fluid"
        />
      </Box>
      <div className="flex-grow-1">
        <Title variant="card" mb={0}>
          {name}
        </Title>
        <Text fontSize={2}>{company}</Text>
      </div>
    </Box>
    <Text color="dark" mb={4} textAlign="justify">
      {children}
    </Text>
  </Box>
);

const Feature2 = () => {
  const intl = useIntl();

  const slickSettings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: breakpoints.md,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Section bg="greyBg">
        <Container>
          <Row className="justify-content-center">
            <Col md="9" lg="8" className="text-center">
              <Box className="text-center">
                <Title>
                  {intl.formatMessage({
                    id: "events.feature2.title",
                  })}
                </Title>
              </Box>
              <Text opacity={0.7}>
                {intl.formatMessage({
                  id: "events.feature2.subtitle1",
                })}
              </Text>
              <Text mb={4} opacity={0.7}>
                {intl.formatMessage({
                  id: "events.feature2.subtitle2",
                })}
              </Text>
            </Col>
          </Row>
          <Row className="justify-content-center mt-3">
            <Col lg="11">
              <SliderStyled {...slickSettings}>
                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <a
                    href="https://www.pa-pc.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ContentCard image={bannerPAPCPng}>
                      {intl.formatMessage({
                        id: "events.feature2.papc",
                      })}
                    </ContentCard>
                  </a>
                </Box>
                <Box
                  css={`
                    &:focus {
                      outline: none;
                    }
                  `}
                >
                  <a
                    href="http://we-opt.com/#/events/cgtour"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <ContentCard image={bannerCGTPng}>
                      {intl.formatMessage({
                        id: "events.feature2.flop_cgt",
                      })}
                    </ContentCard>
                  </a>
                </Box>
              </SliderStyled>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Feature2;
